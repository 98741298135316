<template>
  <div>
    <div class="user-add">
      <h4 class="mb10">用户名字</h4>
      <el-input v-model="acc" />
      <h4 class="mb10 mt20">用户密码</h4>
      <el-input v-model="pwd" show-password/>
      <h4 class="mb10 mt20">再次确认密码</h4>
      <el-input v-model="pwdAgain" show-password/>
      <!-- <el-select v-model="usergroup">
      <el-option label="超级管理员" value="超级管理员"></el-option>
      <el-option label="普通管理员" value="普通管理员"></el-option>
    </el-select> -->
      <el-button class="mt30" type="primary" @click="clickAdd">添加账号</el-button>
    </div>
  </div>
</template>

<script>
// @/  固定指向src根目录！！！
import { addUser } from "@/apis/users";

export default {
  data() {
    return {
      acc: "",
      pwd: "",
      pwdAgain:""
      // usergroup: "普通管理员", //用户组
    };
  },
  methods: {
    clickAdd() {
      if (this.pwdAgain == this.pwd ) {
        addUser({
          userName: this.acc,
          password: this.pwd,
        }).then((res) => {
          if (res.data.code == 0) {
            this.acc = "";
            this.pwd = "";
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }else{
          this.$message({
          message: '两次密码不同',
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user-add {
  width: 500px;
}
</style>
